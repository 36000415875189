import * as addressTypesApi from '@/services/address-types'
import loading from '@/utils/loading.json'
import jsonData from './jsonData.js'
import allButton from '@/components/allButton'
import searchForm from '@/components/searchForm'
import createTable from '@/components/createTable'
import dialogEffect from '@/components/dialogEffect'
import { mixin } from '@/mixins/mixin'

export default {
  name: 'managerUserOrders',
  mixins: [mixin],
  components: {
    searchForm,
    createTable,
    allButton,
    dialogEffect
  },
  data () {
    return {
      tableDataItem: jsonData.tableDataItem,
      tabActivityIndex: 0
    }
  },
  mounted () {
    const _this = this
    _this.queryData(addressTypesApi.getSetup)
  },
  activated () {
    if (this.isActive) this.queryData(addressTypesApi.getSetup)
    this.isActive = true
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = this.$loading(loading) : this.$store.state.app.loading.close()
    },
    handleMainTableAddClick () {
      const _this = this
      _this.$router.push({
        name: 'buddyManagerAddressTypeAdd'
      })
    },
    // 点击操作按钮
    async handleClick (type, data) {
      const _this = this
      if (type?.action === 'view') {
        _this.$router.push({
          name: 'buddyManagerAddressTypeDetail',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'edit') {
        _this.$router.push({
          name: 'buddyManagerAddressTypeEdit',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'destroy') {
        _this.$confirm(_this.$t('text.confirmDeleteThisItem'), _this.$t('text.warning'), {
          type: 'warning'
        }).then(() => {
          _this.setGlobalLoading(true)
          addressTypesApi.deleteAddressTypesId(data.id.value).then(res => {
            _this.setGlobalLoading(false)
            _this.$message({
              type: 'success',
              message: _this.$t('message.operateSuccess')
            })
            _this.search()
          }).catch(() => {
            _this.setGlobalLoading(false)
          })
        }).catch(err => err)
      }
    },
    // 查询
    search (data) {
      if (data) this.searchData = data
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page })
      this.loading = true
      addressTypesApi.getAddressTypes(this.searchData).then(res => {
        if (res.data) {
          this.tableData = []
          this.$nextTick(() => {
            this.tableData = res.data.objects
            this.tabData = res.data.scopes
            Object.assign(this.pageData, res.data.pagination)
          })
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
